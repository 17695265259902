import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import GridItem from '../components/slices/GridItem/GridItem';

const BlogTemplate = ({ data }) => {
  if (!data) return null;

  const blogContent = data.prismicBlog;
  const blog = blogContent.data || {};
  const allPosts = data.allPrismicBlogPost.nodes;
  const onlyPublished = allPosts.filter((post) => post.data.publish_on_website === null || post.data.publish_on_website !== false);
  const filterTags = blogContent.data.filter_tags;

  const { lang, type, url } = blogContent;
  const alternateLanguages = blogContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const metaTitle = blogContent.meta_title || 'CloudArmy Blog';

  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={blog.body} />
      <GridItem posts={onlyPublished} filterTags={filterTags} />
    </Layout>
  );
};

export const query = graphql`
query blogQuery($uid: String, $lang: String) {
  prismicBlog(uid: {eq: $uid}, lang: {eq: $lang}) {
    url
    uid
    type
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    _previewable  
    data {
      filter_tags {
          tag
          tag_display
        }
      section
      meta_title
      display_title {
        html
        raw
        text
      }
      body {
        ... on PrismicBlogDataBodyTextInfo {
          id
          slice_label
          slice_type
          primary {
            width
            title_color
            title_alignment
            title {
              html
              raw
              text
            }
          }
        }
      }
    }
  }
  allPrismicBlogPost(
      sort: { fields: data___date, order: DESC }
  ) {
    nodes {
      _previewable
      id
      url
      tags
      data {
        body {
          ... on PrismicBlogPostDataBodyTextInfo {
            primary {
              description {
                text
                raw
                html
              }
              description_html {
                text
                raw
                html
              }
            }
            id
          }
        }
        display_title {
          raw
          html
          text
        }
        publish_on_website
        date
        author
        author_title
        
        preview_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(height: 350, layout: CONSTRAINED, placeholder: NONE)
            }
          }
        }
      }
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
  prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
  }
  prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
  }
}
`;

export default withPrismicPreview(BlogTemplate);
